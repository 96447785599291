<template>
  <div>
    <headertop ref="headertop"></headertop>
    <div class="goodsInfo">
      <div class="left">
        <el-carousel :interval="4000" arrow="always">
          <el-carousel-item v-for="item in goodsInfo.goodPic" :key="item">
            <img class="bannerImg" :src="item" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right">
        <div class="listA">
          <div class="ls">
            <div class="title">
              <span class="name">{{ goodsInfo.goodName }}</span>
              <span class="desc" @click.stop="shoucang(goodsInfo.id, goodsInfo.collectStatus)">{{ "【" +
                goodsInfo.collectNum + "人收藏】" }}
                <img src="@/assets/shoucang.png" alt="" v-if="goodsInfo.collectStatus == 0" />
                <img src="@/assets/shoucang_a.png" alt="" v-if="goodsInfo.collectStatus == 1" />
                <!-- <img src="../assets/shoucang_a.png" alt=""> -->
              </span>
            </div>
            
            <div class="smdesc" v-if="goodsInfo.unitDetails.length > 0">
              <span>库存：{{ goodsInfo.unitDetails[Inv].stock }}个</span>
            </div>

            <div class="smdesc" style="margin-top: 10px">
              <span v-if="goodsInfo.unitDetails.length > 0">折扣：{{currency.symbol}}{{ goodsInfo.unitDetails[Inv].discount }}</span>
            </div>

            <div class="price" v-if="goodsInfo.unitDetails.length > 0">
              <span class="red">{{currency.symbol}}{{ goodsInfo.unitDetails[Inv].sellPrice }}</span>
              <!-- <span class="num">1个</span> -->
            </div>
            <div class="guige">
              <span>单位：</span>
              <div class="flexBox">
                <div v-for="(item, index) in goodsInfo.unitDetails" :key="index">
                  <el-tag :type="Inv == index ? 'error' : 'info'" style="margin-right: 5px" @click="checkguige(index)">{{
                    item.unitName }} | {{ item.specs }}</el-tag>
                  <div style="margin-top: 10px;">
                    <el-tag :type="index2 == Inv2 && Inv == index ? 'error' : 'info'"  style="margin-right: 5px"  @click="checkguige2(index,index2)" v-for="(item2, index2) in item.propertys" :key="index2">{{ item2.proValue }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
            <div class="smdesc" v-if="smallData">
              <span style="display:block;margin-bottom:5px">最小单位规格：{{ smallData.specs }} / 当前规格：{{
                goodsInfo.unitDetails[Inv].unitName }}</span>
              <span style="display:block">最小单位价格：{{ smallData.sellPrice }} / 当前价格：{{ goodsInfo.unitDetails[Inv].sellPrice
              }}</span>
            </div>

            <div class="addCar" v-if="goodsInfo.unitDetails.length > 0">
              <el-input-number v-model="num" style="width: 240px" @change="handleChange" :min="1"
                :max="goodsInfo.unitDetails[Inv].stock"></el-input-number>
              <div class="rights" @click="addCar(goodsInfo)">
                <img src="../assets/shopCar.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="smdescs">
      <div style="margin-bottom: 20px">商品详情</div>
      <div v-html="goodsInfo.goodDetail"></div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import headertop from "../components/header.vue";
import bottom from "../components/bottom.vue";

import {
  gooddetail,
  goodcollect,
  goodunCollect,
  cartadd,
  onlineget
} from "@/request/api.js";
export default {
  name: "home",
  components: {
    headertop,
    bottom
  },
  data() {
    return {
      num: 1,
      id: "",
      goodsInfo: "",
      Inv: 0,
      Inv2: 0,
      smallData: "",
      currency:{}
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();

    onlineget().then(res=>{
      this.currency = res.data.currency
    })
  },
  mounted() { },

  methods: {
    shoucang(id, type) {
      if (type == 0) {
        //收藏
        goodcollect({ goodId: id }).then((res) => {
          this.$message.success('收藏成功')

          this.getDetail();
        });
      } else {
        //取消收藏
        goodunCollect({ goodId: id }).then((res) => {
          this.$message.success('取消成功')

          this.getDetail();
        });
      }
    },
    handleChange(e) {
      console.log(e);
    },
    getDetail() {
      gooddetail({ id: this.id }).then((res) => {
        console.log(res);
        if (res.data.goodPic) {
          res.data.goodPic = res.data.goodPic.split(",");
        }
        if (res.data.unitDetails && res.data.unitDetails.length > 0) {
          res.data.unitDetails.forEach(item => {
            if (item.isBasic == 1) {
              this.smallData = item
            }
          })
        }

        const regex = new RegExp('<img', 'gi')
        if(res.data.goodDetail){
          res.data.goodDetail = res.data.goodDetail.replace(regex, `<img style="width: 100%; height: auto"`);
        }else{
          res.data.goodDetail = ''
        }
        this.goodsInfo = res.data;
        console.log(this.goodsInfo);
      });
    },
    addCar(item) {
      console.log(item);
      let unitDetailId = item.unitDetails[this.Inv].unitDetailId;
      let gpId = '';

      if (item.unitDetails[this.Inv].propertys && item.unitDetails[this.Inv].propertys.length > 0) {
        gpId = item.unitDetails[this.Inv].propertys[this.Inv2].gpId
      }

      let data = {
        goodNum: this.num,
        goodId: item.id,
        unitDetailId,
        gpId: gpId,
        priceSystemId: item.goodPriceSystemMember.priceSystemId,
      };
      cartadd(data).then((res) => {
        console.log(res);
        this.$refs.headertop.getPrice()
        this.$notify({
          title: "成功",
          message: "添加成功",
          type: "success",
        });
      });
    },
    checkguige(index) {
      this.num = 1;
      this.Inv2 = 0;
      this.Inv = index;
    },
    checkguige2(index,index2) {
      this.Inv = index;
      this.Inv2 = index2;
    },
  },
};
</script>

<style lang="scss" scoped>
.smdescs {
  width: 70%;
  margin: 0 auto;
  margin-top: 60px;
  font-size: 16px;
}

.goodsInfo {
  width: 70%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .left {
    width: 40%;

    .bannerImg {
      width: 100%;
    }
  }

  .right {
    width: 58%;
    position: relative;

    .listA {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      cursor: pointer;
      height: 100%;
    }

    .ls {
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      position: relative;

      .goodsImg {
        position: absolute;
        left: 10px;
        width: 180px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          font-weight: bold;
          font-size: 22px;
        }

        .desc {
          font-size: 14px;
          color: #a9a7a7;
          display: flex;
          align-items: center;

          img {
            width: 20px;
          }
        }
      }

      .addCar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        img {
          width: 40px;
        }
      }

      .guige {
        margin-top: 10px;
        font-size: 14px;
        color: #a9a7a7;
        .flexBox{
          display: flex;
          align-items: center;
        }

        .gui {
          background: #e9e9e9;
          margin-left: 10px;
          padding: 6px 18px;
          border-radius: 5px;
          font-size: 13px;
        }
      }

      .price {
        margin-top: 20px;

        .red {
          color: red;
          font-size: 25px;
          font-weight: bold;
          margin-right: 4px;
        }

        .num {
          font-size: 13px;
          color: #a9a7a7;
          margin-left: 5px;
        }
      }

      .smdesc {
        margin-top: 10px;

        span {
          font-size: 15px;
          color: #6c6c6c;
        }
      }
    }
  }
}
</style>
